@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

.indicator {
    /* height: auto; */
    min-height: 100vh;
    background-image:  url('./images/images/auth-bg\ 1.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  
  .indicator li {
    margin-top: 20px;
    list-style-type: none;
    margin-right: 10px;
    color: #fcfcfc !important;
    text-align: left !important;
    cursor: pointer;
    font-family: inter, Helvetica, sans-serif;
    font-weight: bold;
  
  }
  
  .indicator li.active {
    color: #f7f7f7 !important;
  }
  
  .indicatorvertical {
    background-image: url('./images/images/auth-bg\ 1.png');
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .indicatorvertical li {
   text-align: center;
     margin-top: 30px;
     margin-bottom: 30px;
    list-style-type: none;
    margin-right: 10px;
    /* color: #6076d1; */
    color: #f1f1f1 !important;
    text-align: left !important;
    cursor: pointer;
    /* font-family: inter, Helvetica, sans-serif;
    font-weight: bold; */

    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
   
    font-style: normal;
  
    @media only screen and (max-width:500px) {
      /* font-size: 10px; */
    }
  }
  
  
  .indicatorvertical li.active {
    color: #F7F7F7 !important;;
  }

  .indicatorwebsite {
   
    min-height: 100vh;
    background-image: url('./images/images/auth-bg\ 1.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }
  
  .indicatorwebsite li {
    margin-top: 30px;
    list-style-type: none;
    margin-right: 10px;
    /* color: #6076d1; */
    color: #f1f1f1 !important;
    text-align: left !important;
    cursor: pointer;
    /* font-family: inter, Helvetica, sans-serif;
    font-weight: bold; */

    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
   
    font-style: normal;
  }
  
  .indicatorwebsite li.active {
    color: #f7f7f7 !important;;
  }
  
  .indicatorverticalwebsite {
    background-image: url('./images/images/auth-bg\ 1.png');
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    /* height: fit-content; */
     min-height: 100vh;
  }
  
  .indicatorverticalwebsite li {
    margin-top: 10px;font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 10px;
    list-style-type: none !important;
    color: #78cfdf !important;
    text-align: left !important;
    cursor: pointer;
    /* font-family: inter, Helvetica, sans-serif; */


    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
   
    font-style: normal;


    /* font-weight: bold; */
    display: flex !important;
    justify-content: space-evenly;
  
    @media only screen and (max-width: 500px) {
      /* font-size: 10px; */
    }
  }
  
  .indicatorverticalwebsite li.active {
    color: #f7f7f7 !important;;
  }
  
