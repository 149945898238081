@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

@font-face {
  font-family: Cookie;
  src: url(Cookie-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(Poppins-ExtraLight.ttf);
}

#birthdaydivtwo2nos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#birthday-cardtwo2nos {
  position: relative;
  width: 500px;
  height: 520px;
  background-image: url(bdaytemplatetwo.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.companylogotwo2nos {
  position: absolute;
  top: 15px;
  right: 195px;
}

.companylogotwo2nos img {
  width: 95px;
  height: 95px;
}

#profileImgtwo2nos {
  position: absolute;
  top: 220px;
  left: 270px;
  display: flex;
  flex-direction: column;
}

#profileImgtwo2nos img {
  transform: rotate(0deg);
  width: 150px;
  height: 150px;
  border-radius: 3px;
}

#profileImgtwotwo2nos {
  position: absolute;
  top: 220px;
  left: 80px;
  display: flex;
  flex-direction: column;
}

#profileImgtwotwo2nos img {
  transform: rotate(0deg);
  width: 150px;
  height: 150px;
  border-radius: 3px;
}

.usernametwo2nos {
  transform: rotate(0deg);
  text-align: center;
  font-family: "League Spartan", cursive;
  font-weight: 900;
  font-style: bold;
  font-size: 20px;
  margin-top: 5px;
}

.bdaydobtwo2nos {
  position: absolute;
  bottom: -16px;
  left: -150px;
  width: 460px;
  font-family: "League Spartan", serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 1px;
  margin-bottom: 1px;
}

.bdaydobtwotwo2nos {
  position: absolute;
  text-align: start;
  bottom: -16px;
  left: 230px;
  width: 460px;
  font-family: "League Spartan", serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 1px;
  margin-bottom: 1px;
}

.bdaywishestwo2nos {
  position: absolute;
  text-align: center;
  bottom: 48px;
  left: 84px;
  width: 360px;
  font-family: "League Spartan", serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 1px;
}

.bdayfootertexttwo2nos {
  position: absolute;
  bottom: 10px;
  left: 220px;
  font-family: "League Spartan", serif;

}

.download-button-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.download-button-wrapper button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-button-wrapper button:hover {
  background-color: #45a049;
}

/* Media Queries for Tablet View */
@media only screen and (max-width: 768px) {
  #birthday-cardtwo2nos {
    width: 400px;
    height: 450px;
  }

  .companylogotwo2nos {
    position: absolute;
    top: 30px;
    right: 150px;
  }

  .companylogotwo2nos img {
    width: 80px;
    height: 80px;
  }

  #profileImgtwo2nos {
    position: absolute;
    top: 190px;
    left: 230px;
  }

  #profileImgtwo2nos img {
    width: 110px;
    height: 110px;
  }

  #profileImgtwotwo2nos {
    position: absolute;
    top: 190px;
    left: 60px;
  }

  #profileImgtwotwo2nos img {
    width: 110px;
    height: 110px;
  }

  .usernametwo2nos {
    font-size: 12px;
    text-align: center;

  }

  .bdaydobtwotwo2nos {
    width: 360px;
    font-size: 12px;
    position: absolute;
    left: 195px;
    bottom: -15px;
  }

  .bdaydobtwo2nos {
    width: 360px;
    font-size: 12px;
    position: absolute;
    left: -150px;
    bottom: -15px;
  }

  .bdaywishestwo2nos {
    width: 300px;
    font-size: 16px;
    left: 60px;
    bottom: 62px;
  }

  .bdayfootertexttwo2nos {
    left: 160px;
    bottom: 35px;
    font-size: 14px;
  }
}

/* Media Queries for Mobile View */
@media only screen and (max-width: 480px) {
  #birthday-cardtwo2nos {
    width: 300px;
    height: 380px;
    margin-top: 150px;
  }

  .companylogotwo2nos {
    position: absolute;
    top: 45px;
    right: 115px;
  }

  .companylogotwo2nos img {
    width: 55px;
    height: 55px;
  }

  #profileImgtwo2nos {
    top: 165px;
    left: 175px;
    gap: 5px;
  }

  #profileImgtwo2nos img {
    width: 80px;
    height: 80px;
  }

  #profileImgtwotwo2nos {
    top: 165px;
    left: 55px;
    gap: 5px;
  }

  #profileImgtwotwo2nos img {
    width: 80px;
    height: 80px;
  }

  .usernametwo2nos {
    font-size: 12px;
    top: -10px;
    margin-top: 0px;
  }

  .usernametwotwo2nos {
    font-size: 12px;
    top: -10px;
    right: 10px;
    margin-top: 0px;
  }

  .bdaydobtwo2nos {
    width: 260px;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    bottom: -10px;
    left: -110px;
  }

  .bdaydobtwotwo2nos {
    width: 260px;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    bottom: -10px;
    left: 135px;
  }


  .bdaywishestwo2nos {
    width: 200px;
    font-size: 12px;
    letter-spacing: 0px;
    word-spacing: 0px;
    bottom: 61px;
    left: 60px;
  }

  .bdayfootertexttwo2nos {
    left: 120px;
    bottom: 40px;
    font-size: 14px;
  }

  .download-button-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1000;
  }
}