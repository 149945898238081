.container {

}

.box {
  position: absolute;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  left: 40%;
  top: 30%;
}

.symbol {
  width: 100px;
  height: 100px;
  background-image: url('../src/components/Assets/wifi-disable.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto 20px; /* Center the symbol horizontally */
}

p {
  margin: 5px 0;
}